import React, {Fragment, FunctionComponent} from "react";
import {PartnerRegionData} from "./PartnerMap";
import {Box} from "@mui/material";

export type RegionDetailsProps = {
    region: PartnerRegionData;
};

const RegionDetails: FunctionComponent<RegionDetailsProps> = ({
    region
}) => {

    return (
        <Fragment>
            <Box sx={{
                color: '#5D5D5D',
                fontSize: '18px',
                fontWeight: 700,
                mb: 2
            }}>
                Poslovnice
            </Box>

            {region.cities.map(city =>
                <Fragment key={city.label}>
                    <Box sx={{
                        color: 'primary.main',
                        fontSize: '18px',
                        fontWeight: 600,
                        mt: 2,
                        mb: 1
                    }}>
                        {city.label}
                    </Box>

                    {city.companies.map(company =>
                        <Fragment key={company.label}>
                            <Box sx={{
                                color: 'primary.main',
                                fontSize: '16px',
                                fontWeight: 600,
                                my: 1,
                            }}>
                                {company.label}
                            </Box>

                            {company.addresses.map(address =>
                                <div key={address}>
                                    {address}
                                </div>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default RegionDetails;