import React, {FunctionComponent} from 'react';
import {graphql, PageProps} from 'gatsby';
import Layout from '../components/layout/Layout';
import {Box} from '@mui/material';
import PartnerMap from "../components/PartnerMap/PartnerMap";

type DataType = {
    wpPage: {
        title: string;
        content: string;
    };
};

const PagePage: FunctionComponent<PageProps<DataType>> = ({
    data,
    pageContext
}) => {
    return <Layout title={data.wpPage.title}>
        <Box dangerouslySetInnerHTML={{
            __html: data.wpPage.content,
        }} sx={{
            '& .wp-block-column > p': {
                fontSize: '0.875rem',
                color: '#9a9a9a',
                letterSpacing: '0.5px',
                my: '10px',
            }
        }} />

        {pageContext.pageSlug === 'prodajna-mjesta' && <PartnerMap />}
    </Layout>;
};

export const pageQuery = graphql`
query PageDetails($pageSlug: String) {
  wpPage(slug: {eq: $pageSlug}) {
    title
    content
  }
}
`;

export default PagePage;
