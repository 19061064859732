import React, {Fragment, FunctionComponent, useState} from 'react';
import partnerData from './partner-data.json';
import {Box, Collapse, Grid, IconButton} from "@mui/material";
import RegionDetails from "./RegionDetails";
import CloseIcon from '@mui/icons-material/Close';

export type PartnerMapProps = {};

export type PartnerRegionData = {
    label: string;
    cities: Array<{
        label: string;
        companies: Array<{
            label: string;
            addresses: string[];
        }>;
    }>;
};

type PartnerData = PartnerRegionData[];

const PartnerMap: FunctionComponent<PartnerMapProps> = () => {
    const [currentRegion, setCurrentRegion] = useState<PartnerRegionData|null>(null);


    return (
        <Fragment>
            <Box sx={{
                bgcolor: '#5373ac',
                backgroundImage: 'url(/images/hr-map.webp)',
                backgroundSize: 'auto 100%',
                backgroundPosition: 'center top',
                backgroundRepeat: 'no-repeat',
                minHeight: '236px',
                p: '20px',
                display: 'flex',
                alignItems: 'stretch',
            }}>
                <Grid spacing={2} container sx={{
                    alignContent: 'center'
                }}>
                    {(partnerData as PartnerData).map(region =>
                        <Grid key={region.label} item xs={12} sm={6} md={4} lg={3}
                              onClick={setCurrentRegion.bind(null, region)}
                              sx={{
                                  cursor: 'pointer',
                                  textAlign: 'center',
                                  color: '#f1f1f1',
                                  fontWeight: 700,
                                  fontSize: '14px',
                                  '&:hover': {
                                      color: '#fff',
                                      textDecoration: 'underline'
                                  }
                        }}
                        >
                            {region.label}
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Collapse in={!!currentRegion}>
                <Box sx={{
                    backgroundColor: '#eee',
                    position: 'relative',
                    px: '20px',
                    py: '10px',
                    pr: '70px',
                }}>
                    <IconButton sx={{
                        position: 'absolute',
                        right: '20px',
                        top: '10px',
                        color: 'primary.main',
                    }} onClick={setCurrentRegion.bind(null, null)} size={'large'}>
                        <CloseIcon />
                    </IconButton>

                    {currentRegion && <RegionDetails region={currentRegion} />}
                </Box>
            </Collapse>
        </Fragment>
    );
};

export default PartnerMap;